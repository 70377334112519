import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Router from './modules/app/Router';

UIkit.use(Icons);

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Router />
    </BrowserRouter>
  </React.StrictMode>
);

const menu = document.getElementById('menu');function findLink(el) {
    if (el.tagName == 'A' && el.href) {
        return el.href;
    } else if (el.parentElement) {
        return findLink(el.parentElement);
    } else {
        return null;
    }
};

function callback(e) {
    const link = findLink(e.target);
    if (link == null) { return; }
    UIkit.offcanvas(menu).hide()
};

document.addEventListener('click', callback, false);

navigator.serviceWorker.register(
  new URL('sw.js', import.meta.url),
  {type: 'module'}
);

if (localStorage.getItem('auth') == '') {
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]
  s1.async=true
  s1.differ=true
  s1.src='https://embed.tawk.to/65bc29f98d261e1b5f5af81f/1hljg7tc5'
  // s1.charset='UTF-8'
  s1.setAttribute('crossorigin','*')
  s0.parentNode.insertBefore(s1,s0)
  })()
}